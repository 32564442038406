<template>
  <page-layout page-name="产品匹配">
    <md-field class="main">
      <ValidationObserver ref="form">
        <ValidationProvider
          name="企业名称"
          rules="required|enterpriseName"
          v-slot="{ errors }"
        >
          <md-input-item
            v-model="formData.enterpriseName"
            class="input-item"
            type="text"
            placeholder="企业名称（必填）"
            is-title-latent
            is-highlight
            :maxlength="35"
            title="企业名称"
            readonly
            :error="errors[0]"
            :disabled="querying"
            @focus="handleEnterpriseSelectorShow"
          >
            <md-icon name="user" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <md-input-item
          class="input-item"
          type="digit"
          placeholder="申请人年龄（选填）"
          v-model="formData.age"
          is-title-latent
          :maxlength="2"
          is-highlight
          title="年龄"
          :disabled="querying"
        >
          <md-icon name="calendar" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-input-item
          class="input-item"
          type="money"
          placeholder="年开票额（选填）"
          v-model="formData.billAmount"
          is-title-latent
          is-amount
          :maxlength="16"
          is-highlight
          title="开票"
          :disabled="querying"
        >
          <md-icon name="rmb" slot="left" color="#9579E5"></md-icon>
          <div slot="right">万元</div>
        </md-input-item>
        <md-input-item
          class="input-item"
          type="money"
          placeholder="年缴税额（选填）"
          v-model="formData.taxAmount"
          is-title-latent
          is-amount
          :maxlength="16"
          is-highlight
          title="缴税"
          :disabled="querying"
        >
          <md-icon name="rmb" slot="left" color="#9579E5"></md-icon>
          <div slot="right">万元</div>
        </md-input-item>
        <md-input-item
          class="input-item"
          type="text"
          placeholder="税务等级（选填）"
          v-model="formData.taxLevel"
          is-title-latent
          readonly
          :maxlength="1"
          is-highlight
          title="税务等级"
          @focus="handleTaxGradeSelectorShow"
          :disabled="querying"
        >
          <md-icon name="info" slot="left" color="#9579E5"></md-icon>
        </md-input-item>
        <md-button
          class="submit-btn"
          type="primary"
          size="small"
          :plain="true"
          @click="matching"
          :inactive="querying"
          :loading="querying"
          >{{ submitBtn }}</md-button
        >
      </ValidationObserver>
    </md-field>
    <enterprise-selector
      v-model="enterprise"
      ref="enterprise-selector"
    ></enterprise-selector>
    <tax-grade-selector
      v-model="taxGrade"
      ref="tax-grade-selector"
    ></tax-grade-selector>
  </page-layout>
</template>

<script>
import { Search, Popup, Picker, Notify } from "vant";
import EnterpriseSelector from "@/components/EnterpriseSelector";
import TaxGradeSelector from "@/components/TaxGradeSelector";
import productMatch from "@/api/productMatch";

export default {
  components: {
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Notify.name]: Notify,
    EnterpriseSelector,
    TaxGradeSelector,
  },
  data: function () {
    return {
      formData: {
        enterpriseName: "",
        creditCode: "",
        age: "",
        taxAmount: "",
        billAmount: "",
        taxLevel: "",
      },
      enterprise: {
        enterpriseName: "",
        creditCode: "",
      },
      taxGrade: "",
      querying: false,
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.creditCode = newVal.creditCode;
        this.formData.enterpriseName = newVal.enterpriseName;
      },
    },
    taxGrade(newVal) {
      this.formData.taxLevel = newVal;
    },
  },
  computed: {
    submitBtn() {
      return this.querying ? "匹配中" : "开始匹配";
    },
  },
  methods: {
    handleEnterpriseSelectorShow() {
      this.$refs["enterprise-selector"].open();
    },
    handleTaxGradeSelectorShow() {
      this.$refs["tax-grade-selector"].open();
    },
    matching() {
      this.$refs.form.validate().then((res) => {
        if (!res) {
          return;
        }
        if (!this.formData.enterpriseName) {
          Notify("企业名称不能为空");
          return;
        }
        if (!this.formData.creditCode) {
          Notify("企业信用代码不能为空");
          return;
        }
        this.querying = true;
        productMatch
          .matching(this.formData)
          .then((matchResult) => {
            this.$router.push({
              name: "matchResult",
              params: { id: matchResult.data },
            });
          })
          .catch(() => {
            this.querying = false;
          });
      });
    },
  },
};
</script>

<style scoped>
.submit-btn {
  margin-top: 50px;
}
.main {
  width: 80%;
  margin: 0 auto;
}
.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>